import React from "react";
import { List, ListItem, ListItemText, ListItemIcon } from "@mui/material";
// import { Phone, LocationOn } from "@mui/icons-material";
import { FiberManualRecord } from "@mui/icons-material";
// import MKTypography from "components/MKTypography";
// import MKBox from "components/MKBox";
// import MKTypography from "components/MKTypography";

const MyList = () => {
  return (
    <List sx={{ color: "white", variant: "body2" }}>
      <ListItem sx={{ color: "white", variant: "body2" }}>
        <ListItemIcon>
          <FiberManualRecord color="white" />
        </ListItemIcon>
        <ListItemText>Manufacturing & Engineering</ListItemText>
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <FiberManualRecord color="white" />
        </ListItemIcon>
        <ListItemText>Banking & Finance</ListItemText>
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <FiberManualRecord color="white" />
        </ListItemIcon>
        <ListItemText> Audit & Accounting</ListItemText>
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <FiberManualRecord color="white" />
        </ListItemIcon>
        <ListItemText> Human Resources</ListItemText>
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <FiberManualRecord color="white" />
        </ListItemIcon>
        <ListItemText> Information Technology</ListItemText>
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <FiberManualRecord color="white" />
        </ListItemIcon>
        <ListItemText> Marketing</ListItemText>
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <FiberManualRecord color="white" />
        </ListItemIcon>
        <ListItemText> Supply Chain & Procurement</ListItemText>
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <FiberManualRecord color="white" />
        </ListItemIcon>
        <ListItemText> Retail & Merchandizing</ListItemText>
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <FiberManualRecord color="white" />
        </ListItemIcon>
        <ListItemText> Legal</ListItemText>
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <FiberManualRecord color="white" />
        </ListItemIcon>
        <ListItemText>Property & Construction</ListItemText>
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <FiberManualRecord color="white" />
        </ListItemIcon>
        <ListItemText> Hotels and services</ListItemText>
      </ListItem>
    </List>
  );
};

export default MyList;
