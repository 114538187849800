import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
// import { useState } from "react"; // Import the useState hook
// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import MKBox from "components/MKBox";
// Material Kit 2 React examples
import { Card, CardActionArea, CardContent, CardMedia } from "@mui/material";
//images
import team1 from "assets/images/recruitment-solutions.png";
import team2 from "assets/images/executive-search.png";
import team3 from "assets/images/e-learn.png";
import bgImage from "assets/images/bg-OurServices.png";
// @mui material components
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container"; // Import Container from the correct location
import MKTypography from "components/MKTypography";

// import { blue } from "@material-ui/core/colors";

export default function OurServices() {
  const items = [
    {
      maintitle: {
        title: "Our Services",
      },
      images: {
        bgImg: bgImage,
        img1: team1,
        img2: team2,
        img3: team3,
      },
      service1: {
        title: "Recruitment Solutions",
        desc: "  [B‘n’B]’s executive search and headhunting services ensure providing you with professionals who have a successful career record and who carry high reputation in their respective fields of work. We work extensively, to the finest details, before providing candidates for your consideration so to ensure that these candidates deliver the results that meet or exceed your expectations. We put our share of contribution to our clients’ success through providing and enhancing the quality of human resources and providin advisory that may help our clients retain their best talents.",
      },
      service2: {
        title: "Executive Search",
        desc: "  [B‘n’B] also provides the consultative services leading to filling senior and leadership cadre position in your organization. We maintain a database, mostly through referrals and recommendations that come from other candidates and organizations that we have served. We continually strives to update our database and networks of contacts to keep identifying, reviewing and qualifying potential candidates for targeted senior and leadership position so you could get the best of candidates in the least possible turn around time and effort.!",
      },
      service3: {
        title: "Learning Programs",
        desc: "    One of the key expertise of [B‘n’B] is to facilitate learning & training interventions that support employers, owners, managers and other stakeholders in their current business and future role’s requirements. Our professional and personal development solutions though our customized training workshops and programs equip our clients’ employees with the right skills set and tools required to deliver desired results. Our expertise in developing and delivering customized learning and organizational development solutions.",
      },
    },
  ];
  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          {items.map((item, index) => (
            <Grid
              key={index}
              container
              item
              xs={12}
              lg={8}
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              sx={{ mx: "auto", textAlign: "center" }}
            >
              <MKTypography
                variant="h1"
                color="white"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                {/* Our Services */}
                {item.maintitle.title}
              </MKTypography>
            </Grid>
          ))}
        </Container>
      </MKBox>
      <Container justifyContent="center" alignItems="center">
        {items.map((item, index) => (
          <Grid container spacing={5} py={5} px={5} key={index}>
            <Grid item xs={12} sm={4}>
              <Card sx={{ maxWidth: 345, backgroundColor: "#49a3f1" }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="140"
                    image={item.images.img2}
                    alignItems="center"
                  />
                  <CardContent>
                    <MKBox bgColor="white" coloredShadow="info" borderRadius="lg">
                      <MKTypography variant="h6" py={2} px={2}>
                        {item.service1.title}
                      </MKTypography>
                      <MKTypography variant="body2" px={2}>
                        {item.service1.desc}
                        <br></br>
                        <br></br>
                      </MKTypography>
                    </MKBox>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card
                sx={{
                  maxWidth: 345,
                  backgroundColor: "#49a3f1",
                  color: "white",
                }}
              >
                <CardActionArea>
                  <CardMedia component="img" height="140" image={item.images.img1} />
                  <CardContent>
                    <MKBox bgColor="white" coloredShadow="info" borderRadius="lg">
                      <MKTypography variant="h6" py={2} px={2}>
                        {item.service2.title}
                      </MKTypography>
                      <MKTypography variant="body2" px={2}>
                        {item.service2.desc}
                        <br></br>
                        <br></br>
                        <br></br>
                      </MKTypography>
                    </MKBox>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card sx={{ maxWidth: 345, backgroundColor: "#49a3f1" }}>
                <CardActionArea>
                  <CardMedia component="img" height="140" image={item.images.img3} />
                  <CardContent>
                    <MKBox bgColor="white" coloredShadow="info" borderRadius="lg">
                      <MKTypography variant="h6" py={2} px={2}>
                        {item.service3.title}
                      </MKTypography>
                      <MKTypography variant="body2" px={2}>
                        {item.service3.desc}
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                      </MKTypography>
                    </MKBox>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        ))}
      </Container>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}
