import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Avatar from "@mui/material/Avatar";
// import Typography from "@mui/material/Typography";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import logo from "../../../../src/assets/images/logo.png";

// const testimonials = [
//   {
//     id: 1,
//     name: "John Doe",
//     position: "CEO, Company Name",
//     image: "https://via.placeholder.com/100",
//     quote:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla cursus lorem sit amet vestibulum fermentum. Nulla facilisi. Fusce accumsan luctus ante in pellentesque.",
//   },
//   // {
//   //   id: 2,
//   //   name: "Jane Smith",
//   //   position: "CTO, Company Name",
//   //   image: "https://via.placeholder.com/100",
//   //   quote:
//   //     "Vestibulum vel justo a eros tempor pharetra non at odio. Nulla facilisi. Integer maximus euismod urna, id rhoncus odio bibendum vel.",
//   // },
//   // Add more testimonials here...
// ];

function DefaultFooter({ content }) {
  const { brand, socials, menus, copyright } = content;

  return (
    <MKBox component="footer">
      <Container>
        <Grid container spacing={3}>
          {/* First Grid */}
          <Grid item xs={12} md={6} sx={{ ml: "auto", mb: 3 }}>
            <MKBox>
              <Link to={brand.route}>
                <MKBox component="img" src={logo} maxWidth="11rem" mb={2} />
              </Link>
              <MKTypography variant="h6">{brand.name}</MKTypography>
            </MKBox>
            <MKBox display="flex" alignItems="center" mt={3}>
              {socials.map(({ icon, link }, key) => (
                <MKTypography
                  key={link}
                  component="a"
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  variant="h3"
                  color="dark"
                  opacity={0.8}
                  mr={key === socials.length - 1 ? 0 : 2.5}
                >
                  {icon}
                </MKTypography>
              ))}
            </MKBox>
          </Grid>

          {/* Second Grid */}
          <Grid item xs={12} md={6}>
            {menus.map(({ name: title, items }) => (
              <Grid key={title} item xs={6} md={6} sx={{ mb: 3 }}>
                <MKTypography
                  display="block"
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                  mb={1}
                >
                  {title}
                </MKTypography>
                <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
                  {items.map(({ name, route, href }) => (
                    <MKBox key={name} component="li" p={0} m={0} lineHeight={1.25}>
                      {href ? (
                        <MKTypography
                          component="a"
                          href={href}
                          target="_blank"
                          rel="noreferrer"
                          variant="button"
                          fontWeight="regular"
                          textTransform="capitalize"
                        >
                          {name}
                        </MKTypography>
                      ) : (
                        <MKTypography
                          component={Link}
                          to={route}
                          variant="button"
                          fontWeight="regular"
                          textTransform="capitalize"
                        >
                          {name}
                        </MKTypography>
                      )}
                    </MKBox>
                  ))}
                </MKBox>
              </Grid>
            ))}
          </Grid>
          {/* Third Grid (Testimonials)
          <Grid item xs={12} sx={{ mt: 3 }}>
            <Container>
              <Grid container spacing={4}>
                {testimonials.map((testimonial) => (
                  <Grid key={testimonial.id} item xs={12} sm={6} md={4}>
                    <Avatar
                      src={testimonial.image}
                      alt={testimonial.name}
                      sx={{ width: 100, height: 100 }}
                    />
                    <Typography variant="h6" gutterBottom>
                      {testimonial.name}
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                      {testimonial.position}
                    </Typography>
                    <Typography variant="body2">{testimonial.quote}</Typography>
                  </Grid>
                ))}
              </Grid> */}
          {/* </Container>
          </Grid> */}
          {/* Common Grid */}
          <Grid item xs={12} sx={{ textAlign: "center", my: 3 }}>
            {/* {testimonial} */}
            {copyright}
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

// Typechecking props for the DefaultFooter
DefaultFooter.propTypes = {
  content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])).isRequired,
};

export default DefaultFooter;
