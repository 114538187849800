import React from "react";
// import { SwipeableViews } from "react-swipeable-views";
// import MKBox from "components/MKBox";
// import MKTypography from "components/MKTypography";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
// import { Carousel } from "react-responsive-carousel";
// import { useState, useEffect } from "react";

// import { makeStyles } from "@mui/styles";
// import SwipeableViews from "react-swipeable-views";

import byco from "assets/images/client-logo/byco.png";
import candyland from "assets/images/client-logo/candyland.png";
// import century from "assets/images/client-logo/century.png";
import dalda from "assets/images/client-logo/dalda.png";
import domino from "assets/images/client-logo/domino.png";
import habib from "assets/images/client-logo/habib.png";
import hayat from "assets/images/client-logo/hayat.png";
// import hilal from "assets/images/client-logo/hilal.png";
import hilton from "assets/images/client-logo/hilton.png";
import kolson from "assets/images/client-logo/kolson.png";
import master from "assets/images/client-logo/master.png";
import shan from "assets/images/client-logo/shan.png";
import tapal from "assets/images/client-logo/tapal.png";
import youngs from "assets/images/client-logo/youngs.png";
import zil from "assets/images/client-logo/zil.png";
import { Carousel } from "react-responsive-carousel";

const carouselItems = [
  {
    id: 1,
    alt: "byco",
    src: byco,
  },
  {
    id: 2,
    alt: "candyland",
    src: candyland,
  },

  {
    id: 3,
    alt: "dalda",
    src: dalda,
  },
  {
    id: 4,
    alt: "domino",
    src: domino,
  },
  {
    id: 5,
    alt: "habib",
    src: habib,
  },
  {
    id: 6,
    alt: "hayat",
    src: hayat,
  },

  {
    id: 7,
    alt: "hilton",
    src: hilton,
  },
  {
    id: 8,
    alt: "kolson",
    src: kolson,
  },
  {
    id: 9,
    alt: "master",
    src: master,
  },
  {
    id: 10,
    alt: "shan",
    src: shan,
  },
  {
    id: 11,
    alt: "tapal",
    src: tapal,
  },
  {
    id: 12,
    alt: "youngs",
    src: youngs,
  },
  {
    id: 13,
    alt: "zil",
    src: zil,
  },
];
const MyCarousel = () => {
  const carouselRef = React.useRef(null);

  const handleKeyDown = (e) => {
    if (e.key === "ArrowLeft") {
      carouselRef.current?.previous();
    } else if (e.key === "ArrowRight") {
      carouselRef.current?.next();
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Carousel
        ref={carouselRef}
        autoPlay
        infiniteLoop
        showStatus={false}
        showThumbs={false}
        centerMode
        centerSlidePercentage={33.33}
        renderArrowPrev={(onClickHandler, hasPrev) =>
          hasPrev && (
            <button
              type="button"
              onClick={onClickHandler}
              style={{
                position: "absolute",
                left: 0,
                zIndex: 1,
                top: "50%",
                transform: "translateY(-50%)",
                display: "flex",
                alignItems: "center",
                // backgroundColor: "rgba(0, 0, 0, 0.5)",
                color: "white",
                backgroundColor: "#49a3f1",
                padding: 10,
                cursor: "pointer",
              }}
            >
              <KeyboardArrowLeft fontSize="large" color="white" />
            </button>
          )
        }
        renderArrowNext={(onClickHandler, hasNext) =>
          hasNext && (
            <button
              type="button"
              onClick={onClickHandler}
              style={{
                position: "absolute",
                right: 0,
                zIndex: 1,
                top: "50%",
                transform: "translateY(-50%)",
                display: "flex",
                alignItems: "center",
                color: "white",
                // backgroundColor: "rgba(0, 0, 0, 0.5)",
                backgroundColor: "#49a3f1",
                padding: 10,
                cursor: "pointer",
              }}
            >
              <KeyboardArrowRight fontSize="large" color="white" />
            </button>
          )
        }
        onKeyDown={handleKeyDown}
        tabIndex={0}
        style={{ padding: "20px" }}
      >
        {carouselItems.map((item) => (
          <div key={item.id}>
            <img src={item.src} alt={item.alt} style={{ height: "200px" }} />
          </div>
        ))}
      </Carousel>
    </div>
  );
};
export default MyCarousel;
