import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import routes from "routes";
import footerRoutes from "footer.routes";
import MKBox from "components/MKBox";
import Grid from "@mui/material/Grid";
import bgImage from "assets/images/bg-MyaboutUs.png";
import Container from "@mui/material/Container";
import MKTypography from "components/MKTypography";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function MyAboutUs() {
  const items = [
    {
      maintitle: {
        title: "About [B‘n’B] ",
      },
      desc: "Bucks & Bricks [B‘n’B] is a young company under a young and dynamic leadership. The organization aspires to be a prime point of reference for Pakistan and MENA regions for quality Talent Management Services",
      est: {
        title: "Establishment",
        desc: " Established in 2011, [B‘n’B] has very quickly matured into a reliable partner of choice with employers on candidate sourcing and with job seekers to finding a dream job. [B‘n’B] offers an array of solutions & advisory services on Talent Management consulting such as Recruitment Solutions, HR Process Development and Outsourcing, Human Resources Consulting with specialized focus on developing customized Training Programs and Executive Search.",
      },
      vision: {
        title: "Vision",
        desc: " [B’n B] vision is to combine the experience skills, expertise and committed of our associated partners and consultants in brining to you the solutions that have risen from best practices, are marketoriented and lead to organizational success.",
      },
      mission: {
        title: "Mission",
        desc: "   Our mision is simple: the bottom-line! Our clients’ results determine our company’s bottom-line; our success or failure. Hence we closely work with your organization in a way as it was our own company. That’s why our solutions are readily accepted, respected and implemented, making [B‘n’B] your lasting partner to bringing bricks so that you can make bucks!",
      },
    },
  ];

  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          {items.map((item, index) => (
            <Grid
              key={index}
              container
              item
              xs={12}
              lg={8}
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              sx={{ mx: "auto", textAlign: "center" }}
            >
              <MKTypography
                variant="h1"
                color="white"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                {item.maintitle.title}
              </MKTypography>
            </Grid>
          ))}
        </Container>
      </MKBox>
      <Container>
        <MKBox
          variant="gradient"
          bgColor="info"
          coloredShadow="info"
          borderRadius="xl"
          shadow="lg"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          mt={{ xs: 20, sm: 18, md: 20 }}
          mb={{ xs: 20, sm: 18, md: 20 }}
          mx={3}
          px={5}
          py={3}
        >
          {items.map((item, index) => (
            <div key={index}>
              <MKTypography variant="body2" color="white" py={3}>
                <b>{item.desc}</b>
              </MKTypography>
              <Accordion bgColor="blue">
                <AccordionSummary expandIcon={<ExpandMoreIcon />} background-color="cornflowerblue">
                  <MKTypography variant="h6">{item.est.title}</MKTypography>
                </AccordionSummary>
                <AccordionDetails>
                  <MKTypography variant="body2">{item.est.desc}</MKTypography>
                </AccordionDetails>
              </Accordion>
              <Accordion py={3}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <MKTypography variant="h6">{item.vision.title}</MKTypography>
                </AccordionSummary>
                <AccordionDetails>
                  <MKTypography variant="body2">{item.vision.desc}</MKTypography>
                </AccordionDetails>
              </Accordion>
              <Accordion py={3}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <MKTypography variant="h6">{item.mission.title}</MKTypography>
                </AccordionSummary>
                <AccordionDetails>
                  <MKTypography variant="body2">{item.mission.desc}</MKTypography>
                </AccordionDetails>
              </Accordion>
            </div>
          ))}
        </MKBox>
      </Container>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}
