import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import MKBox from "components/MKBox";
//components
// import CarouselComponent from "pages/LandingPages/Home/components/carousel";
import MKTypography from "components/MKTypography";
// @mui material components
import Grid from "@mui/material/Grid";
import bgImage from "assets/images/bg-home.png";
import Slider from "../../../pages/LandingPages/Home/components/slider";

export default function Home() {
  const items = [
    {
      title: "Bucks n Bricks",
      title1: "Talent Management Solutions",
      desc: "BnB has been providing multilateral services to a wide range of local, national and multinational organizations which makes it the first choice as your partner for Human Resources and Talent management solutions. Be it recruitment executive search HR processes development, automation or development of learning interventions, BnB has delivered exactly the kind of solutions that reaped outcome based rewards for the organization.",
    },
  ];
  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <Slider />
      <MKBox
        bgColor="white"
        borderRadius="xl"
        shadow="lg"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        mt={{ xs: 20, sm: 18, md: 20 }}
        mb={{ xs: 20, sm: 18, md: 20 }}
        mx={3}
        my={3}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <MKBox
              display={{ xs: "none", lg: "flex" }}
              width="calc(80% - 2rem)"
              height="calc(65vh - 2rem)"
              justifyContent="center"
              borderRadius="lg"
              ml={8}
              mt={2}
              my={5}
              sx={{ backgroundImage: `url(${bgImage})` }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={10}
            md={7}
            lg={6}
            xl={4}
            ml={{ xs: "auto", lg: 6 }}
            mr={{ xs: "auto", lg: 6 }}
          >
            <MKBox>
              <MKBox
                variant="gradient"
                bgColor="info"
                coloredShadow="info"
                borderRadius="lg"
                p={2}
                mx={0}
                // mt={-3}
              >
                {items.map((item, index) => (
                  <div key={index}>
                    <MKTypography variant="h3" color="white">
                      {item.title} <br></br>
                    </MKTypography>
                    <MKTypography variant="h5" color="white">
                      {item.title1}
                    </MKTypography>
                    <MKBox p={3}>
                      <MKTypography variant="body2" color="white">
                        {item.desc}
                      </MKTypography>
                    </MKBox>
                  </div>
                ))}
              </MKBox>
            </MKBox>
          </Grid>
        </Grid>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}
