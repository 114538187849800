import React from "react";
// import Image1 from "../../../../assets/images/home-slider-1.png";
// import Image2 from "../../../../assets/images/home-slider-2.png";
import Image1 from "../../../../assets/images/home-slider-6.png";
// import Image3 from "../../../../assets/images/home-slider-3.png";
import Image4 from "../../../../assets/images/home-slider-4.png";
import Image5 from "../../../../assets/images/home-slider-5.png";

// import Image3 from "../../../../assets/images/bg-presentation.jpg";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Slider = () => {
  const sliderItems = [
    // { id: 1, image: Image1, alt: "Image 1" },
    // { id: 2, image: Image2, alt: "Image 2" },
    { id: 3, image: Image1, alt: "Image 1" },
    { id: 4, image: Image4, alt: "Image 4" },
    { id: 5, image: Image5, alt: "Image 5" },
  ];

  return (
    <Carousel autoPlay infiniteLoop showThumbs={false} showStatus={false}>
      {sliderItems.map((item) => (
        <div key={item.id}>
          <img src={item.image} alt={item.alt} />
        </div>
      ))}
    </Carousel>
  );
};

export default Slider;
