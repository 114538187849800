import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
// import { useState } from "react"; // Import the useState hook
// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import MKBox from "components/MKBox";
//images
// import team1 from "assets/images/team-5.jpg";
import bgImage from "assets/images/bg-chooseus.png";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container"; // Import Container from the correct location
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

export default function WhyBnB() {
  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Why Choose Us
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Container>
        <MKBox component="section" py={12}>
          <Container>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} lg={6}>
                <Grid container justifyContent="flex-start">
                  <Grid item xs={12} md={6}>
                    <MKBox mb={5}>
                      <DefaultInfoCard
                        icon="public"
                        title="Clients' Referrals"
                        description="[B‘n’B]’s pledge to serve its customers at its best capabilities and focus to partner up for a long relationship."
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKBox mb={5}>
                      <DefaultInfoCard
                        icon="payments"
                        title="Personalized Service"
                        description="[B‘n’B] is a trustworthy partner who listens, understands, and serves you with the most suitable outcome."
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKBox mb={{ xs: 5, md: 0 }}>
                      <DefaultInfoCard
                        icon="apps"
                        title="Agility"
                        description="[B‘n’B] provides speedy solutions to your business problems."
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKBox mb={{ xs: 5, md: 0 }}>
                      <DefaultInfoCard
                        icon="3p"
                        title="Improved platform"
                        description="[B‘n’B] has delivered exactly the kind of solutions that reaped outcome-based rewards for the organization."
                      />
                    </MKBox>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
                <CenteredBlogCard
                  image="https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
                  title="Get in touch"
                  description="Feel Free to contact"
                  action={{
                    type: "internal",
                    route: "/pages/landing-pages/contact-us",
                    color: "info",
                    label: "Contact us Now",
                  }}
                />
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Container>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}
