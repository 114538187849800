import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
// import { useState } from "react"; // Import the useState hook
// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import MKBox from "components/MKBox";
//images
// import team1 from "assets/images/team-5.jpg";
import bgImage from "assets/images/home-slider-2.png";

// @mui material components
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container"; // Import Container from the correct location
import MKTypography from "components/MKTypography";
import Listing from "./components/listing";
import Carousel from "./components/carousel";
// Material Kit 2 React examples
// import MKBox from "components/MKBox";
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

export default function OurClients() {
  const items = [
    {
      titles: {
        title: "Our Clients",
      },
    },
  ];
  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          {items.map((item, index) => (
            <Grid
              key={index}
              container
              item
              xs={12}
              lg={8}
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              sx={{ mx: "auto", textAlign: "center" }}
            >
              <MKTypography
                variant="h1"
                color="white"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                {item.titles.title}
              </MKTypography>
            </Grid>
          ))}
        </Container>
      </MKBox>
      <MKBox component="section" py={4}>
        <Container>
          <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
            <Grid item xs={12} md={3}>
              <DefaultCounterCard
                count={30}
                suffix="+"
                title="Organizations"
                description="Of “high-performing” level are led by a certified recruiters"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <DefaultCounterCard
                count={12}
                suffix="+"
                title="Industry Sectors"
                description="That meets quality standards required by our users"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <DefaultCounterCard
                count={24}
                suffix="/7"
                title="Support"
                description="Actively engage team members that finishes on time"
              />
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <Container>
        <MKBox
          variant="gradient"
          bgColor="info"
          coloredShadow="info"
          borderRadius="xl"
          shadow="lg"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          mt={{ xs: 10, sm: 12, md: 6 }}
          mb={{ xs: 20, sm: 18, md: 20 }}
          mx={8}
          px={5}
          // py={-4}
          pb={5}
        >
          <MKBox color="white">
            <MKTypography color="white" variant="body1" py={8}>
              In just 5 years of our operations, [B‘n’B] has successfully served more than 30
              organizations of different sectors in various Human Resource consulting areas and have
              placed more than 100 individuals by extending recruitment and executive search
              solutions. Our partners mostly belong to the following industry sectors
            </MKTypography>
            <MKTypography color="white" variant="body2">
              <Listing />
            </MKTypography>
          </MKBox>
        </MKBox>
      </Container>
      <MKBox
        display="flex"
        flexDirection="column"
        justifyContent="center"
        mt={{ xs: 10, sm: 12, md: 6 }}
        mb={{ xs: 20, sm: 18, md: 20 }}
        mx={8}
        px={2}
      >
        <Carousel />
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}
